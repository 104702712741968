@import '@/styles/artifacts.scss';





























































































.page-title {
  margin-bottom: 10px;
  color: #666;
  h2 {
    position: relative;
    &:after {
      content: '';
      position: absolute;
      bottom: -4px;
      left: 0;
      width: 40%;
      height: 3px;
      background: linear-gradient(90deg, #e60000, transparent);
    }
  }
}
.content {
  margin-bottom: 30px;
  padding: 25px;
  border-radius: 12px;
  background: white;
  border: 1px solid #f0f0f0;
  transition: all 0.3s ease;
  &:hover {
    transform: translateX(5px);
    box-shadow: 0 5px 15px rgba(230, 0, 0, 0.1);
    border-color: #e60000;
  }
  a {
    color: #333;
  }
  h3 {
    color: #333;
    font-size: 20px;
    margin: 0 0 4px;
    font-weight: 600;
  }
  p {
    color: #666;
    font-size: 16px;
    margin: 0 0 2px;
  }
  span {
    &.label {
      display: inline-block;
      width: 80px;
    }
  }
}
